<template>
  <AppPage title="Create Post" container hide-app-bar>
    <div ref="emptyDiv"></div>
    <input
      type="file"
      ref="fileInput"
      class="d-none"
      multiple
      accept="video/*,image/*"
      @change="getBrowserImage"
    />

    <v-progress-linear
      :active="loading"
      striped
      rounded
      color="primary"
      height="25"
      class="mb-5"
      :value="postUploadProgress"
    >
      <template v-slot:default="{ value }">
        <strong>{{ Math.ceil(value) }}%</strong>
      </template>
    </v-progress-linear>

    <div v-if="medias.length === 0">
      <v-img v-ripple @click="fileHandler" :src="placeholderImage"></v-img>
    </div>

    <Media
      v-else
      showRemoveIcon
      :media="medias"
      :cover="true"
      @remove-media="removeMediaFile"
    />

    <form @submit.prevent="uploadPost">
      <v-textarea
        v-model="caption"
        hide-details
        outlined
        rows="2"
        class="mt-10"
        row-height="20"
        @focus="SET_BOTTOM_BAR(true)"
        @blur="SET_BOTTOM_BAR(false)"
        :label="$vuetify.lang.t('$vuetify.enter_caption')"
      ></v-textarea>

      <div class="d-flex mt-10">
        <v-spacer></v-spacer>
        <app-button :loading="loading" :block="false" type="submit">{{
          $vuetify.lang.t("$vuetify.publish")
        }}</app-button>
      </div>
    </form>

    <v-bottom-sheet v-model="previewMode" fullscreen>
      <v-sheet height="100%">
        <v-app-bar flat hide-on-scroll color="white">
          <v-toolbar-title>Preview</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text @click="previewMode = false" color="primary">Close</v-btn>
        </v-app-bar>
        <FeedPreview :medias="medias" />
      </v-sheet>
    </v-bottom-sheet>

    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5">
          {{ $vuetify.lang.t("$vuetify.upload_with") }}
        </v-card-title>
        <v-card-text>
          <v-row class="text-center">
            <v-col cols="6">
              <v-btn class="ma-2" icon color="primary" @click="openWith(false)">
                <v-icon>mdi-image-multiple </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn class="ma-2" icon color="primary" @click="openWith(true)">
                <v-icon>mdi-camera </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </AppPage>
</template>

<script>
import Media from "@/components/media/media";
import FeedPreview from "@/components/feed-preview/feed-preview";

import placeholderImage from "@/mixin/placeholder-image";
import MediaPicker from "@/mixin/get-mob-media";

import { VIDEO_EXTENSIONS_ARRAY } from "@/constants";
import {
  bytesToMb,
  // backgroundModeEnable,
  // backgroundModeDisable,
} from "@/helpers";

import { mapState, mapMutations, createNamespacedHelpers } from "vuex";
const feedModule = createNamespacedHelpers("feed");
export default {
  name: "create-post",
  components: { Media, FeedPreview },
  mixins: [placeholderImage, MediaPicker],
  data() {
    return {
      drag: false,
      medias: [],
      caption: "",
      previewMode: false,
      dialog: false,
    };
  },
  computed: {
    ...mapState(["isCordova"]),
    ...feedModule.mapState(["loading", "postUploadProgress"]),
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    ...feedModule.mapActions(["CREATE_POST"]),
    ...mapMutations(["SET_BOTTOM_BAR"]),
    isImage(src) {
      if (typeof src === "string") {
        let strParts = src.split(".");
        let extension = strParts[strParts.length - 1];

        if (VIDEO_EXTENSIONS_ARRAY.includes(extension.toLowerCase())) {
          return false;
        } else {
          return true;
        }
      } else {
        return src.type.includes("video") ? false : true;
      }
    },
    getBrowserImage(e) {
      const files = e.target.files;
      const errors = [];
      for (const file of files) {
        if (file.type.includes("video") || file.type.includes("image")) {
          const fileSize = bytesToMb(file.size);

          if (fileSize > 20) {
            errors.push(
              `Size of ${file.name} is more then 20MB. Please upload it under 20mb`
            );
          } else {
            if (this.medias.length >= 10) {
              window.showAlert("Cannot upload more then 10 images/videos");
              break;
            }
            const src = URL.createObjectURL(file);
            this.medias.push({
              id: Math.random() + Date.now(),
              file,
              src,
              type: file.type,
            });
          }
        }
      }

      if (errors.length) {
        window.showAlert(errors.join(" \n"), "File Limit Exceed");
      }

      e.target.value = "";
      // backgroundModeDisable();
    },
    openWith(isCamera) {
      // backgroundModeEnable();
      if (isCamera) {
        const camera = navigator.camera;
        const options = {
          quality: 50,
          sourceType: camera.PictureSourceType.CAMERA,
          destinationType: camera.DestinationType.FILE_URI,
          encodingType: camera.EncodingType.JPEG,
          mediaType: camera.MediaType.ALLMEDIA,
          correctOrientation: true,
        };
        camera.getPicture(
          this.onSuccessHandler,
          (e) => {
            console.log(e);
            if (e !== "No Image Selected") {
              window.showAlert(
                "We need your camera access to capture the photo. Please enable the camera access in the settings.",
                "Camera Access Failed!"
              );
            }
          },
          options
        );
      } else {
        this.$refs.fileInput.click();
        // this.getMediaFromMob();
        this.dialog = false;
      }
    },
    async getMediaFromMob() {
      try {
        if (this.isCordova) {
          const medias = await this.openMediaPicker();
          const errors = [];

          this.medias = medias.filter((media) => {
            const file = media.file;
            const fileSize = bytesToMb(file.size);

            if (fileSize > 20) {
              errors.push(
                `Size of ${file.name} is more then 20MB. Please upload it under 20mb`
              );
              return false;
            }
            return true;
          });

          if (errors.length) {
            window.showAlert(errors.join(" \n"), "File Limit Exceed");
          }
        } else {
          this.$refs.fileInput.click();
        }
      } catch (error) {
        window.showAlert(error, "SOme Error");
        console.log(error);
      }
    },
    onSuccessHandler(path) {
      //  let vm = this;
      window.resolveLocalFileSystemURL(
        path,
        (entry) => {
          entry.file(
            (file) => {
              let reader = new FileReader();
              reader.onloadend = (e) => {
                let fileBlob = new Blob([e.target.result], {
                  type: file.type,
                });
                const src = URL.createObjectURL(fileBlob);
                fileBlob.lastModifiedDate = new Date();
                fileBlob.name = file.name;
                fileBlob.mimeType = file.type;

                this.medias.push({
                  id: Math.random() + Date.now(),
                  file: fileBlob,
                  src,
                  type: file.type,
                });

                this.dialog = false;
                // backgroundModeDisable();
              };
              reader.readAsArrayBuffer(file);
            },
            (err) => {
              window.showAlert("error number 123123" + err, "Error");
            }
          );
        },
        // fail on getting file entry
        (err) => {
          window.showAlert("Error while getting the file" + err.code, "Error");
        }
      );
    },
    fileHandler() {
      if (this.isCordova) {
        this.dialog = true;
      } else {
        this.$refs.fileInput.click();
      }
    },
    removeMediaFile(m) {
      this.medias = this.medias.filter((media) => media.id !== m.id);
    },
    uploadPost() {
      this.$refs.emptyDiv.focus();
      const payload = {
        details: this.caption,
        medias: this.medias.map((m) => m.file),
      };
      this.CREATE_POST(payload);
    },
  },
  mounted() {
    document.querySelector("body").classList.add("overflow-hidden");
  },
  beforeDestroy() {
    document.querySelector("body").classList.remove("overflow-hidden");
    this.SET_BOTTOM_BAR(false);
  },
};
</script>

<style lang="scss" scoped>
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.media-group {
  padding: 0;
  list-style: none;

  span {
    display: flex;
    overflow: auto;
    padding: 1rem 0;

    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px #e7e7e7;
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background-color: #0dbff2;
      // outline: 1px solid green;
    }
  }

  &-item {
    margin: 0.5rem;
    position: relative;
    max-width: 250px;

    &::v-deep video {
      max-height: 250px;
      max-width: 250px;
    }

    &-close {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    &.add-image {
      height: 250px;
      width: 250px;
      display: grid;
      place-items: center;
    }
  }

  .sortable-chosen,
  .ghost {
    opacity: 0.5;
    transform: scale(0.9);
  }
}
</style>

// cordova-plugin-mediapicker-dmcsdk
