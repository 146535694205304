var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[(false)?_c('v-window',{attrs:{"transition":"v-expand-transition"},model:{value:(_vm.current),callback:function ($$v) {_vm.current=$$v},expression:"current"}},_vm._l((_vm.allImages),function(m,i){return _c('v-window-item',{key:i,staticClass:"media-item text-center win-item"},[(false)?_c(_vm.currentComponent(m),{tag:"component",attrs:{"src":_vm.mediaSource(m),"max-height":"350px","loadImg":""}}):_vm._e(),_c('Cropper',{ref:"cropper",refInFor:true,staticClass:"cropper",attrs:{"src":_vm.mediaSource(m),"stencil-size":{
            width: 300,
            height: 300,
          },"stencil-props":{
            handlers: {},
            movable: false,
            resizable: false,
            aspectRatio: 1,
          },"image-restriction":"stencil"},on:{"change":function (ev) { return _vm.imageRatioChange(m, ev); }}})],1)}),1):_vm._e(),_c('div',{staticClass:"preview"},[_c('Cropper',{ref:"cropper",staticClass:"cropper",attrs:{"src":_vm.mediaSource(_vm.allImages[_vm.current]),"stencil-size":{
          width: 300,
          height: 300,
        },"stencil-props":{
          handlers: {},
          movable: false,
          resizable: false,
          aspectRatio: 1,
        },"image-restriction":"stencil"},on:{"change":_vm.imageRatioChange}})],1)],1),_c('div',{key:_vm.reRender,staticClass:"thumbnail"},_vm._l((_vm.medias),function(m,i){return _c('div',{key:i,staticClass:"media--item",class:i == _vm.current ? 'primary' : '',on:{"click":function($event){_vm.current = i}}},[(!_vm.isVideo(m))?_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.mediaSource(m)}}):_c('video',{staticClass:"img-fluid",attrs:{"src":_vm.mediaSource(m)}})])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }