<template>
  <div>
    <v-container>
      <v-window v-model="current" transition="v-expand-transition" v-if="false">
        <v-window-item
          class="media-item text-center win-item"
          v-for="(m, i) in allImages"
          :key="i"
        >
          <component
            v-if="false"
            :is="currentComponent(m)"
            :src="mediaSource(m)"
            max-height="350px"
            loadImg
          />

          <Cropper
            :src="mediaSource(m)"
            class="cropper"
            ref="cropper"
            :stencil-size="{
              width: 300,
              height: 300,
            }"
            :stencil-props="{
              handlers: {},
              movable: false,
              resizable: false,
              aspectRatio: 1,
            }"
            image-restriction="stencil"
            @change="(ev) => imageRatioChange(m, ev)"
          />
        </v-window-item>
      </v-window>

      <div class="preview">
        <Cropper
          :src="mediaSource(allImages[current])"
          class="cropper"
          ref="cropper"
          :stencil-size="{
            width: 300,
            height: 300,
          }"
          :stencil-props="{
            handlers: {},
            movable: false,
            resizable: false,
            aspectRatio: 1,
          }"
          image-restriction="stencil"
          @change="imageRatioChange"
        />
      </div>
    </v-container>

    <div class="thumbnail" :key="reRender">
      <div
        @click="current = i"
        :class="i == current ? 'primary' : ''"
        class="media--item"
        v-for="(m, i) in medias"
        :key="i"
      >
        <img v-if="!isVideo(m)" :src="mediaSource(m)" class="img-fluid" />
        <video :src="mediaSource(m)" v-else class="img-fluid"></video>
      </div>
    </div>
  </div>
</template>

<script>
import SingleImage from "@/components/media/single-image.vue";
import VideoPlayer from "@/components/media/video-player.vue";

import { VIDEO_EXTENSIONS_ARRAY } from "@/constants";

import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "feed-preview",
  props: {
    medias: { type: Array, default: () => [] },
  },
  components: { Cropper, SingleImage },
  data() {
    return {
      current: 0,
      reRender: false,
    };
  },
  computed: {
    swiperOption() {
      return {
        loop: false,
        slidesPerView: 4,
      };
    },
    allImages() {
      return this.medias.filter((media) => media.type.includes("image"));
    },
  },
  methods: {
    isVideo(src) {
      if (typeof src === "string") {
        let component = false;
        let strParts = src.split(".");
        let extension = strParts[strParts.length - 1];

        if (VIDEO_EXTENSIONS_ARRAY.includes(extension.toLowerCase())) {
          component = true;
        }

        return component;
      } else {
        return src.type.includes("video");
      }
    },
    currentComponent(src) {
      return this.isVideo(src) ? VideoPlayer : SingleImage;
    },
    mediaSource(media) {
      if (typeof media === "string") {
        return media;
      }
      return media.src;
    },
    imageRatioChange(details) {
      console.log({ details });
    },
  },
  mounted() {
    // setTimeOut(() => this.reRender = !this.reRender , 1000)

    setTimeout(() => (this.reRender = !this.reRender), 500);
  },
};
</script>

<style lang="scss" scoped>
.thumbnail {
  margin-top: 1rem;
  border: 0.5px solid rgba(51, 51, 51, 0.5);
  border-right-color: transparent;
  border-left-color: transparent;

  display: flex;
  overflow-x: auto;
}

// .media-item {
// }
.media--item {
  text-align: center;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  .img-fluid {
    width: 80px;
    height: auto;
    border-radius: 8px;
  }
}

.win-item {
  min-height: 300px;
  display: grid;
  place-items: center;
}

.cropper {
  height: 350px;
  background: #ddd;
}
</style>
